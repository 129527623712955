import { User, UserService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultCatch } from '@common/defaults';

export const fetchCurrentUser = async (
    contextData: ContextData,
    isClientSide: boolean = false,
): Promise<User | undefined> => {
    if (!contextData.userID) return undefined;

    const request = new ApiBaseRequest(contextData, isClientSide);
    const userService = new UserService(request);

    return await userService
        .getByIdUser({
            id: contextData.userID,
        })
        .catch(defaultCatch);
};
