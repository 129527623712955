import { isArray } from 'lodash';
import { ParsedUrlQueryInput } from 'node:querystring';

import { SearchParams } from '@common/defaults';
import { cleanLeadingSlash } from '@common/utils/UrlUtil';
import { Translate } from '@pxr/i18n';

import { Route } from '../types/Route';

const translatableRegex = /^[a-z0-9-]+$/i;
export const formatInternalRoute = (
    route: Route,
    contextSlug: string | undefined,
    __t: Translate | null,
    searchParams?: SearchParams | ParsedUrlQueryInput,
): string => {
    let path: string = cleanLeadingSlash(route);

    // Translate pieces
    if (__t) {
        path = path
            .split('/')
            .map((piece) => (translatableRegex.test(piece) ? __t(`url:${piece}`) : piece))
            .join('/');
    }

    // Add routePrefix
    path = path && contextSlug ? `/${contextSlug}/${path}` : `/${contextSlug || ''}${path || ''}`; // Add slash before routePrefix

    if (searchParams) {
        for (let [key, value] of Object.entries(searchParams)) {
            // Adjust the regular expression to include the correct brackets
            if (typeof value !== 'string' && isArray(value)) value = value.join('/');
            path = path.replace(new RegExp(`\\[\\[?\\.?\\.?\\.?${key}\\]?\\]`, 'g'), String(value));
        }
    }

    // remove optional catch-all routes, e.g. [[...slug]]
    path = path.replace(/\/\[\[\.\.\..+?\]\]/, '');

    return path;
};
