/**
 *  FIXME: PB-6771 Split userContext from contextData
 *
 *  We're walking on thin ice here by using a state-like Session.useClaimValue hook within the ContextDataProvider
 *  in combination with a useContextData() hook that is used in 161 components.
 *  We don't need the user data in many places, so it should be stripped from the context data
 */

import { FC, PropsWithChildren, useState } from 'react';
import { useEffect } from 'react';

import { User } from '@common/clients/api';
import { ResolvedContextData } from '@common/defaults';
import { useLoggedInStatus } from '@common/supertokens';
import { checkIfBettingEnabled } from '@common/utils/betting';
import { fetchCurrentUser } from '@web/handlers/fetchCurrentUser';
import { fetchCurrentUserProfile } from '@web/handlers/fetchCurrentUserProfile';

import { NextContext } from './NextContext';

export interface ContextDataProviderProps extends PropsWithChildren {
    contextData: ResolvedContextData;
}

const updateContextDataWithUser = (contextData: ResolvedContextData, user: User) => {
    if (user) {
        contextData.user = user;
        contextData.userID = user.id;
        if (!checkIfBettingEnabled(user)) {
            contextData.allowBetting = false;
        }
    }
};

export const ContextDataProvider: FC<ContextDataProviderProps> = ({
    contextData: originalContextData,
    children,
}) => {
    const [contextData, setContextData] = useState<ResolvedContextData>(originalContextData);
    const { isLoggedIn: supertokensIsLoggedin, isUnverified: supertokensIsUnverified } =
        useLoggedInStatus(contextData);
    const [user, setUser] = useState<User | undefined>(contextData.user);

    if (user) updateContextDataWithUser(contextData, user);

    useEffect(() => {
        if (contextData.supertokens && contextData.supertokens.isUnverified !== supertokensIsUnverified) {
            contextData.supertokens.isUnverified = supertokensIsUnverified;
            // Creating a new object due to avoid incorrect memoization
            setContextData({ ...contextData });
        }
    }, [supertokensIsUnverified]);

    useEffect(() => {
        if (supertokensIsLoggedin && !user) {
            fetchCurrentUserProfile(contextData, true).then((user) => {
                if (user) {
                    setUser(user);
                    updateContextDataWithUser(contextData, user);
                    // Creating a new object due to avoid incorrect memoization
                    setContextData({ ...contextData });
                }
            });
        }
    }, [supertokensIsLoggedin]);

    // TODO: Remove and use fetchCurrentUserProfile after PB-6160 has been merged
    useEffect(() => {
        if (contextData.userID && (!user || user?.id !== contextData.userID)) {
            fetchCurrentUser(contextData, true).then((user) => {
                if (user) {
                    setUser(user);
                    updateContextDataWithUser(contextData, user);
                    // Creating a new object due to avoid incorrect memoization
                    setContextData({ ...contextData });
                }
            });
        }
    }, [contextData.userID]);

    return <NextContext.Provider value={contextData}>{children}</NextContext.Provider>;
};
