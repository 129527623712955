import fs from 'fs';
import path from 'path';

export const flattenNamespaces = (namespaces: Record<string, string[]>) => Object.values(namespaces).flat();

/**
 * @name getNamespacesByRoute
 * @description Get the namespaces by route path using the namespacesPerPage object
 * @param route
 * @param namespacesPerPage
 */
export const getNamespacesByRoute = (
    route: string | undefined,
    namespacesPerPage: Record<string, string[]>,
) => {
    const defaultNamespaces = namespacesPerPage['*'];

    // fallback
    if (!route) {
        return defaultNamespaces;
    }

    // ensure route starts with '/' for proper matching
    if (!route.startsWith('/')) {
        route = `/${route}`;
    }

    const REGEXP_KEY = 'rgx:';
    const keys = Object.keys(namespacesPerPage);

    const namespaces = [...defaultNamespaces];

    for (let key of keys) {
        // exact match case
        if (key === route) {
            namespaces.push(...namespacesPerPage[key]);
        }

        // reg exp case
        if (key.startsWith(REGEXP_KEY) && new RegExp(key.replace(REGEXP_KEY, '')).test(route)) {
            namespaces.push(...namespacesPerPage[key]);
        }
    }

    return namespaces.flat();
};

const getNamespacesFile = () => {
    const namespacesPath = path.resolve(`${process.cwd()}/namespaces.json`);

    if (!fs.existsSync(namespacesPath)) {
        throw new Error(
            `The namespaces.json file is missing in the root of the project. Please create the file with the proper namespaces.`,
        );
    }

    return JSON.parse(fs.readFileSync(namespacesPath, 'utf-8'));
};

/**
 * @name getAdminNamespacesByRoute
 * @description Get the admin namespaces by route path
 * @param route
 */
export const getAdminNamespacesByRoute = async (route: string | undefined) => {
    const namespaces = await getNamespacesFile();
    return getNamespacesByRoute(route, namespaces);
};

/**
 * @name getWebNamespacesByRoute
 * @description Get the web namespaces by route path
 * @param route
 */
export const getWebNamespacesByRoute = async (route: string | undefined) => {
    const namespaces = await getNamespacesFile();
    return getNamespacesByRoute(route, namespaces);
};

/**
 * @name getFallbackNamespaces
 * @description Get the fallback namespaces from the locale/en folder
 * @returns {string[]}
 * @throws {Error}
 * @example
 * import { getFallbackNamespaces } from '@pxr/i18n';
 *
 * const namespaces = getFallbackNamespaces(); // ['common', 'footer', 'header', 'home', 'news']
 *
 * @note The function is used to generate the fallback namespaces for the i18next instance.
 */
export const getFallbackNamespaces = () => {
    const files = fs.readdirSync(path.resolve(`${process.cwd()}/../i18n/locale/en`));

    if (!files.length) {
        throw new Error(
            `The locale/en folder is missing in the i18n folder. Please create the folder with the proper namespaces.`,
        );
    }

    const namespaces = files.map((file) => file.replace('.json', ''));

    return namespaces;
};
