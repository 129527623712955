import { ParsedUrlQueryInput } from 'node:querystring';

import { FullContext } from '@common/clients/api';
import { Translate } from '@pxr/i18n';

import { Route } from '../types/Route';
import { formatInternalRoute } from './formatInternalRoute';

export function formatRoute(
    context: FullContext,
    route: Route,
    __t: Translate | null,
    query?: ParsedUrlQueryInput,
): string {
    const path = formatInternalRoute(route, context.slug, __t, query);
    const isDevMode = (process.env.APP_ENV || process.env.NODE_ENV) === 'development';
    const protocol = isDevMode ? 'http' : 'https';
    return `${protocol}://${context.url}${path}`;
}
