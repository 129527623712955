import { GetServerSideProps, GetServerSidePropsContext } from 'next';

import { ContextData } from '@common/defaults';
import { getAdminNamespacesByRoute, getServerTranslations, SSRConfig } from '@pxr/i18n';

import { populateContextData } from './populateContextData';

interface Props extends SSRConfig {
    contextData: ContextData;
}

export const defaultGetServerSideProps: GetServerSideProps<Props> = async (
    context: GetServerSidePropsContext,
) => {
    const contextData = await populateContextData(context);

    return {
        props: {
            contextData,
            ...(await getServerTranslations(
                contextData.context.locale,
                await getAdminNamespacesByRoute(contextData.path),
            )),
        },
    };
};
