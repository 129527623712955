import Cookies from 'cookies';
import JsCookies from 'js-cookie';
import newrelic from 'newrelic';
import { GetServerSidePropsContext } from 'next';
import absoluteUrl from 'next-absolute-url';

import { ExperimentGroupOrRobots } from '@common/clients/api';
import { logger } from '@common/logger';
import { SupertokensStatus } from '@common/supertokens/SupertokensStatus';
import { Duration } from '@common/types';
import { getExperimentGroup } from '@web/utils/getExperimentGroup';

import { CachedContextData, ContextData } from './ContextData';
import { getCachedContextData } from './getCachedContextData';

export const cachedContextDataByHost: Record<string, CachedContextData> = {};

const isExperimentGroup = (x: unknown): x is ExperimentGroupOrRobots =>
    typeof x === 'string' && Object.values(ExperimentGroupOrRobots).includes(x as ExperimentGroupOrRobots);

/** @deprecated Please ensure to use populateContextDataWithAds in cms/web react pages */
export const populateContextData = async (
    serverContext: GetServerSidePropsContext | false,
): Promise<ContextData> => {
    // context is passed in NextInjector either as cookies or as script element
    const cookies = serverContext ? new Cookies(serverContext.req, serverContext.res) : JsCookies;
    const cookieContext: any = serverContext
        ? {
              appAgent: cookies.get('app-agent'),
              domainID: cookies.get('domainID'),
              experimentGroup: cookies.get('experimentGroup'),
              isPlatformContext: cookies.get('isPlatformContext'),
              locale: cookies.get('locale'),
              magnoliaSessionID: cookies.get('publishing_session'),
              sAccessToken: cookies.get('sAccessToken'),
              sAntiCsrf: cookies.get('sAntiCsrf'),
              sFrontToken: cookies.get('sFrontToken'),
              sRefreshToken: cookies.get('sRefreshToken'),
              'st-last-access-token-update': cookies.get('st-last-access-token-update'),
              userAgent: cookies.get('userAgent'),
              userID: cookies.get('userID'),
              userSessionID: cookies.get('userSessionID'),
          }
        : JSON.parse((document?.getElementById('_next_context')?.textContent as string) || '{}');

    const origin = serverContext ? absoluteUrl(serverContext.req).origin : window.location.origin;
    const url = new URL(origin);
    const hostname = url.hostname;
    const cachedContextData = await getCachedContextData(hostname, !!serverContext);

    logger.registerAdditionalContext({
        origin,
        hostname,
        pathname: serverContext ? serverContext.req.url : undefined,
        route: serverContext ? serverContext.resolvedUrl : undefined,
    });

    const context =
        cachedContextData.context ||
        cachedContextData.contexts.find((context) => {
            if (cookieContext.domainID) return context.id === Number(cookieContext.domainID);

            const isMatching = context.pattern && new RegExp(context.pattern, 'i').test(hostname);
            // Compare locale with slug ( since original path is not available in the serverContext due to the rewrites )
            if (isMatching && context.slug) {
                const locale = serverContext ? serverContext.locale : cookieContext.locale;
                return new RegExp(context.slug).test(locale);
            }

            return isMatching;
        });

    if (!context) {
        throw Error(`Context could not be determined for ${hostname}`);
    }

    // TODO: remove this once php code is gone
    let isDarkWebCall: boolean = false;
    if (
        serverContext &&
        serverContext.req.headers['user-agent'] &&
        /Guzzle/.test(serverContext.req.headers['user-agent'])
    ) {
        isDarkWebCall = true;
    }

    const contextData: ContextData = {
        ...cachedContextData,
        context,
        query: serverContext ? serverContext.query : {},
        path: serverContext ? serverContext.req.url : undefined,
        allowAds: true,
        allowBetting: true,
        allowTracking: true,
        isPlatformContext: cookieContext?.isPlatformContext || false,
        isDarkWebCall,
        magnoliaSessionID: cookieContext.magnoliaSessionID || '',
        userID: Number(cookieContext.userID) || 0,
        userSessionID: (cookieContext.userSessionID as string) || '',
        userAgent:
            (cookieContext.userAgent && decodeURIComponent(cookieContext.userAgent)) ||
            (serverContext && serverContext.req.headers['user-agent']) ||
            'unknown',
        supertokens: {
            status: SupertokensStatus.UNKNOWN,
            isUnverified: false,
            sAccessToken: cookieContext.sAccessToken ?? '',
            sRefreshToken: cookieContext.sRefreshToken ?? '',
            sFrontToken: cookieContext.sFrontToken ?? '',
            sAntiCsrf: cookieContext.sAntiCsrf ?? '',
            'st-last-access-token-update': cookieContext['st-last-access-token-update'] ?? '',
        },
    };

    if (serverContext && !cookieContext?.experimentGroup && !isDarkWebCall) {
        contextData.experimentGroup = getExperimentGroup(contextData?.userAgent || '');
        cookies.set('experimentGroup', contextData.experimentGroup, {
            path: '/',
            expires: new Date(Date.now() + 365 * Duration.day),
        });
    } else if (cookieContext?.experimentGroup) {
        contextData.experimentGroup = cookieContext?.experimentGroup;
    }

    if (cookieContext.appAgent) contextData.userAgent += ' ' + cookieContext.appAgent;

    if (/Playwright/i.test(contextData.userAgent || '')) {
        contextData.allowAds = false;
        contextData.allowTracking = false;
    }

    if (serverContext) {
        if (serverContext.query) {
            for (const [key, value] of Object.entries(serverContext.query)) {
                if (typeof value !== 'undefined') {
                    newrelic.addCustomAttribute(key, value.toString());
                    if (key === 'experimentGroup' && isExperimentGroup(value)) {
                        contextData.experimentGroup = value;
                    }
                }
            }
            if (serverContext.query.ads === 'false') {
                contextData.allowAds = false;
            }
            if (serverContext.query.tracking === 'false') {
                contextData.allowTracking = false;
            }
        }

        newrelic.addCustomAttribute('userAgent', contextData.userAgent || '');
        newrelic.addCustomAttribute('platform', contextData.context.platform);
        newrelic.addCustomAttribute('context.id', contextData.context.id);
        newrelic.addCustomAttribute('hostname', contextData.hostname);
        newrelic.addCustomAttribute('path', serverContext.resolvedUrl);
    }

    return contextData;
};
